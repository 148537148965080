
import { createDicomWebApi } from '../../default/src/DicomWebDataSource';

function loadDynamicUrls()
{
  let params = new URLSearchParams(window.location.search);
  let token = null;
  let url = null;

  if (params.has('data'))
  {
    let data = null;
    try
    {
      data = JSON.parse(window.atob(params.get('data')));

      if (!data.hasOwnProperty('token') || !data.hasOwnProperty('url'))
      {
        console.error('Invalid `data` keys');
      }
      else
      {
        token = data['token'];
        url = data['url'];
      }
    }
    catch (err)
    {
      console.error('An error occurred while trying to parse the `data` query string param.');
    }
  }
  else
  {
    console.error('An error occurred while trying to parse the query strings.');
  }

  let wado = null;
  let qido = null;

  if (token && url)
  {
    wado = `${url}/wado/${token}`;
    qido = `${url}/dicom-web/${token}`;
  }

  return {
    wado: wado,
    qido: qido
  };
}

export default
{
  id: '@mattlashey/tti-extension',

  /**
   * LIFECYCLE HOOKS
   */
  preRegistration()
  {
    console.log('TTI Extension Pre-registration...');
  },
  beforeExtInit()
  {
    console.log('Before extension initialized.');
  },
  beforeExtDestroy()
  {
    console.log('After extension initialized.');
  },

  /**
   * MODULES
   */
  getDataSourcesModule()
  {
    const dynamicUrls = loadDynamicUrls();

    return [
      {
        name: 'ttiOrthancServer',
        type: 'webApi',
        createDataSource: (config, userAuthenticationService) => {
          if(Object.keys(config).length === 0)
          {
            console.log('No configuration is provided...');
            config = {
              name: 'ttiOrthancServer',
              wadoUriRoot: dynamicUrls.wado,
              qidoRoot: dynamicUrls.qido,
              wadoRoot: dynamicUrls.qido,
              qidoSupportsIncludeField: true,
              imageRendering: 'wadors',
              thumbnailRendering: 'wadors',
              // qidoSupportsIncludeField: false,
              // supportsReject: false,
              // imageRendering: 'wadors',
              // thumbnailRendering: 'wadors',
              enableStudyLazyLoad: true,
              // supportsFuzzyMatching: false,
              // supportsWildcard: true,
              // staticWado: true
            };
          }

          return createDicomWebApi(config, userAuthenticationService);
        },
      }
    ];
  }
};
